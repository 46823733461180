export type Article = {
  id: string
  image: string
  cheminMdx: string
  title: string
}
export const articlesPush: Article[] = [
  {
    id: 'grossesse',
    image: 'sellpush/Grossesse.jpg',
    cheminMdx: '/mdx/Sellpush.mdx',
    title: "Envie d'une séance photo grossesse ?",
  },
  {
    id: 'naissance',
    image: 'sellpush/Naissance.jpg',
    cheminMdx: '/mdx/Naissance.mdx',
    title: "Envie d'un shooting photo naissance ?",
  },
]
export const findArticleById = (id: string) => {
  return articlesPush.find((article) => article.id === id)
}
