import React, { ReactNode } from 'react'
import { graphql, type HeadFC, type PageProps, Link } from 'gatsby'
import { HeaderAndFooterLayout } from '../layouts/HeaderAndFooter'
import { Typography } from '../components/photographie/Typography'
import { css } from '../../styled-system/css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text'
import { useContentImages } from '../hooks/useContentImages'
import SellingPush from '../components/photographie/SellingPush'
import BlogBody from '../components/photographie/BlogBody'
import SEO from '../components/SEO'

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_: unknown, children: ReactNode) => (
      <Typography as="h1">{children}</Typography>
    ),
    [BLOCKS.HEADING_2]: (_: unknown, children: ReactNode) => (
      <Typography as="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (_: unknown, children: ReactNode) => (
      <Typography as="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_4]: (_: unknown, children: ReactNode) => (
      <Typography as="h4">{children}</Typography>
    ),
    [BLOCKS.HEADING_5]: (_: unknown, children: ReactNode) => (
      <Typography as="h5">{children}</Typography>
    ),
    [BLOCKS.HEADING_6]: (_: unknown, children: ReactNode) => (
      <Typography as="h6">{children}</Typography>
    ),
    [BLOCKS.UL_LIST]: (_: unknown, children: ReactNode) => (
      <ul style={{ listStyle: 'inherit' }}>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (_: unknown, children: ReactNode) => (
      <ol style={{ listStyle: '-moz-initial' }}>{children}</ol>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const images = useContentImages()
      const asset = images.find(
        (image) => image.contentful_id === node.data.target.sys.id
      )
      if (asset) {
        return (
          <img
            src={asset.url}
            style={{ maxWidth: '100%', margin: '5% auto' }}
            alt=""
          />
        )
      }
    },
    [BLOCKS.HR]: () => (
      <hr style={{ width: '100%', marginTop: '50px', marginBottom: '50px' }} />
    ),
    [BLOCKS.PARAGRAPH]: (_: any, children: ReactNode) => {
      if (_.content.at(0).value === '') {
        return <p style={{ padding: '20px' }}></p>
      }
      return <p>{children}</p>
    },
  },
}

const BlogPage: React.FC<PageProps> = ({ data }) => {
  const typedData = data as {
    contentfulBlogs: {
      id: string
      title: string
      slug: string
      description: string
      type:string
      images: { url: string }[]
    }
    allContentfulBlogs: {
      edges: {
        node: {
          id: string
          slug: string
          title: string
          createdAt: Date
          cover: { url: string }
        }
      }[]
    }
  }

  const {contentfulBlogs : {type}} = typedData

  const body = renderRichText(
    typedData.contentfulBlogs
      .description as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
    options
  )

  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          padding: '0 20px',
          fontSize: '18px',
          position: 'relative',
          justifyContent: 'center',
          maxWidth: '1350px',
          margin: 'auto',
          '@media (max-width: 768px)': { margin: '100px auto 0' },
        })}
      >
        <Typography
          className={css({
            alignSelf: 'flex-start',
            margin: '50px auto',
            fontSize: '48px',
            color: '#594A2E',
            width: '90%',
            '@media (max-width: 768px)': {
              fontSize: '32px',
            },
          })}
          as="h1"
        >
          {typedData.contentfulBlogs.title}
        </Typography>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '1350px',
            width: '90%',
            margin: 'auto',
            '@media (min-width: 768px)': {
              flexDirection: 'row',
              alignItems: 'flex-start',
            },
          })}
        >
          <div
            className={css({
              width: '100%',
              '@media (min-width: 768px)': {
                width: '70%',
                paddingRight: '50px',
              },
            })}
          >
            <BlogBody
              body={body}
              images={typedData.contentfulBlogs.images}
            ></BlogBody>
          </div>

          <div
            className={css({
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
              boxShadow: '0 0 3px 2px grey',
              marginTop: '20px',
              '@media (min-width: 768px)': {
                width: '30%',
                marginTop: '0',
              },
            })}
          >
            <h2 className={css({ padding: '10px 20px' })}>Derniers articles</h2>
            {typedData.allContentfulBlogs.edges.map(({ node }) => (
              <Link
                key={node.id}
                to={`/photographie/blog/${node.slug ? node.slug : node.title}`}
                className={css({ textDecoration: 'none', color: 'inherit' })}
              >
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    padding: '10px 20px',
                    userSelect: 'none',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                    '&:hover': {
                      backgroundColor: '#f9f9f9',
                    },
                  })}
                >
                  <div
                    className={css({
                      width: '100px',
                      aspectRatio: '1',
                    })}
                  >
                    <img
                      className={css({
                        border: '2px solid #F2D98D',
                        objectFit: 'cover',
                        height: '100px',
                        maxHeight: '100px',
                        minWidth: '100px',
                      })}
                      src={node.cover.url}
                      alt={node.title}
                    />
                  </div>
                  <div>
                    <Typography
                      className={css({
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      })}
                      as="h5"
                    >
                      {node.title}
                    </Typography>
                    <Typography
                      className={css({ fontSize: '12px', color: '#666' })}
                      as="span"
                    >
                      {new Date(node.createdAt).toLocaleDateString()}
                    </Typography>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div
          className={css({
            position: 'absolute',
            bottom: '0',
            left: '25%',
            width: '50%',
            borderBottom: 'solid 4px #F2D98D',
            display: 'none',
          })}
        ></div>
        <div
          className={css({
            position: 'absolute',
            bottom: '0',
            left: '25%',
            width: '50%',
            borderBottom: 'solid 4px #F2D98D',
            display: 'none',
          })}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '2rem',
        }}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#D9C58B',
            height: '0.2rem',
            width: '66.666%',
          })}
        ></div>
      </div>
      <SellingPush id={type}></SellingPush>
    </HeaderAndFooterLayout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulBlogs(id: { eq: $id }) {
      title
      slug
      type
      description {
        raw
      }
      images {
        url
      }
    }
    allContentfulBlogs {
      edges {
        node {
          id
          title
          slug
          createdAt
          cover {
            url
          }
        }
      }
    }
  }
`

export default BlogPage

// Head component
export const Head: HeadFC = ({ data }) => {
  const typedData = data as any

  return (
    <SEO
      title={`${typedData.contentfulBlogs.title} | Digit Universe`}
      description={`${typedData.contentfulBlogs.title}, Digit Universe Photographie, photographe professionnel à Lille`}
      robotsContent="index,follow"
    />
  )
}
