import React, { CSSProperties, ReactNode } from 'react'
import { css } from '../../../styled-system/css'

interface BodyItem {
    type: string
    props: {
        children?: React.ReactNode
        src?: string
        alt?: string
        style?: CSSProperties
    }
}

interface Image {
    url: string
}

interface Props {
    body: BodyItem[]
    images: Image[]
}

export default function BlogBody({ body, images }: Props) {
    const imgArray = body.filter((elem, index: number) => elem.type === 'img' && body[index - 1]?.type !== 'img');
    const imgIndices = body.reduce((indices: number[], elem, index) => {
        if (elem.type === 'img') {
            indices.push(index);
        }
        return indices;
    }, []);
    const groupedText: BodyItem[][] = [];
    let startIndex = 1;
    for (const imgIndex of imgIndices) {
        groupedText.push(body.slice(startIndex, imgIndex));
        startIndex = imgIndex + 1;
    }
    groupedText.push(body.slice(startIndex));
    const nonEmptyGroups = groupedText.filter((group) => group.length > 0);
    const groupedDivs = nonEmptyGroups.map((group, index) => (
        <div key={index}>
            {group.map((item, idx) => (
                item.type === "p" ? <p key={idx}>{item.props.children}<br></br></p> :
                    <h4 className={css({ fontSize: '26px', margin: '30px 0' })} key={idx}>{item.props.children}<br></br></h4>
            ))}
        </div>
    ));
    if (imgArray.length > 1 && images.length > 1) {
        return (
            <div className={css({ maxWidth: '1200px', width: '100%' })}>
                <b>{body[0].props.children}</b>
                <br></br>
                <button className={css({ height: '30px', margin: '20px 0 20px', background: '#F2D98D', fontWeight: 'bold', padding: '0 10px' })}>grossesse</button>
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '0 auto',
                    })}
                >
                    {groupedDivs.map((item, index) => (
                        <div
                            key={index}
                            className={css({
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '30px',
                                marginBottom: '20px',
                                fontSize: '16px',
                                '@media (max-width: 768px)': {
                                    flexDirection: 'column',
                                    gap: '15px',
                                }
                            })}
                        >
                            {index % 2 === 0 && imgArray[index] && imgArray[index].props && (
                                <>
                                    <img
                                        className={css({
                                            border: '4px solid #F2D98D',
                                            width: '350px',
                                            borderTopRightRadius: '20px',
                                            borderBottomRightRadius: '20px',
                                            '@media (max-width: 768px)': {
                                                width: '100%',
                                                borderRadius: '0',

                                            }
                                        })}
                                        src={imgArray[index].props.src}
                                        alt={imgArray[index].props.alt}
                                        style={{
                                            ...imgArray[index].props.style,
                                        }}
                                    />
                                    {item}
                                </>
                            )}
                            {index % 2 !== 0 && imgArray[index] && imgArray[index].props && (
                                <>
                                    {item}
                                    <img
                                        className={css({
                                            border: '4px solid #F2D98D',
                                            width: '300px',
                                            borderTopLeftRadius: '20px',
                                            borderBottomLeftRadius: '20px',
                                            '@media (max-width: 768px)': {
                                                width: '100%',
                                                borderRadius: '0',
                                            }
                                        })}
                                        src={imgArray[index].props.src}
                                        alt={imgArray[index].props.alt}
                                        style={{
                                            ...imgArray[index].props.style,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className={css({ width: '90%', maxWidth: '1200px' })}>
                {<img
                    className={css({
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        border: '4px solid #F2D98D',
                        borderRadius: '10px',
                        marginBottom: '50px',
                    })}
                    src={images[0].url}
                />}
                <div className={css({ fontSize: '18px' })}>
                    {body.map((item, index) => {
                        return (
                            <React.Fragment key={index}>{item as ReactNode}<br></br></React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    }
}
