import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '../../../styled-system/css'
import '../../styles/MdxText.css'
import { findArticleById, Article } from '../../types/article'
import Reservation from './reservation' // Corrected the casing of the file name

export default function SellingPush({ id }: { id: string }) {
  const article: Article | undefined = findArticleById(id)
  if (!article) {
    return null
  }

  const articleText: string = article?.cheminMdx

  if (!articleText) {
    return <div>Article text not found</div>
  }

  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          body
          internal {
            contentFilePath
            description
          }
        }
      }
    }
  `)

  const mdxBody = data.allMdx.nodes.find(
    (node: { internal: { contentFilePath: string | string[] } }) =>
      node.internal.contentFilePath.includes(articleText)
  )
  if (!mdxBody) {
    return <div>MDX not found</div>
  }
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      })}
    >
      <div
        className={css({
          display: 'flex',
          width: '100%',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        })}
      >
        <div
          className={css({
            margin: 'auto',
          })}
        >
          <h2
            className={css({
              mt: '50px',
              mb: '50px',
              fontSize: '30px',
              textAlign: 'center',
            })}
          >
            {article.title}
          </h2>
          <div
            className={css({
              width: '100%',
              margin: 'auto',
            })}
          >
            <div
              className="MDXText"
              dangerouslySetInnerHTML={{ __html: mdxBody.body }}
            />
          </div>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'row',
            })}
          ></div>
        </div>
        <Reservation id={article.id} image={article.image}></Reservation>
      </div>
    </div>
  )
}
